import { navigate } from "gatsby"
import React from "react"
import styled from "styled-components"

import { Box, Text } from "../../components/Core"
import WhatsAppIcon from "../../images/svg/icons/WhatsAppIcon.svg"

const StyledLongBox = styled(Box)`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-top: 2.5rem;
  padding: 5rem 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightBlue};
`

const TitleText = styled(Text)`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  line-height: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
`
const DescriptionText = styled(Text)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  line-height: 1.25rem;
  font-weight: 400;
  margin-bottom: 0.875rem;
`

const SmallButton = styled.button`
  padding: 0 1.5rem;
  min-height: 3rem;
  background: transparent;
  color: ${({ theme }) => theme.colors.light};
  background-color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.02em;

  border: 1px solid #dfe1e9;
  box-sizing: border-box;
  border-radius: 40px;
  font-weight: 500;
  font-size: 0.875rem;

  img {
    margin-top: -5px;
    marging-left: 2px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.textHover};
    border-radius: 40px;
    text-align: center;
    color: #ffffff;
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
  }

  @media (max-width: 992px) {
    width: 10rem;
  }
`

const LeftDecorator = styled.div`
  display: block;
  position: absolute;
  height: 10.25rem;
  width: 8rem;
  left: 0;
  transform: translateX(-50%);

  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.textHover};
  border-radius: 400px;
`
const RightDecorator = styled.div`
  display: block;
  position: absolute;
  height: 12.5rem;
  width: 6.25rem;
  transform: translateY(-11rem);
  right: 0;

  overflow: hidden;
  border-radius: 400px 0 0 400px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.primary} transparent
    ${({ theme }) => theme.colors.primary} ${({ theme }) => theme.colors.primary};
`

export default function HaveDoubts() {
  return (
    <StyledLongBox>
      <LeftDecorator />
      <RightDecorator />
      <TitleText>¿Tienes dudas?</TitleText>
      <DescriptionText>Estamos para apoyarte todo el tiempo</DescriptionText>
      <SmallButton onClick={() => navigate(`https://wa.me/573012128715`)}>
        Hablemos{"\u00A0"}
        {"\u00A0"}
        <img src={WhatsAppIcon} alt="WhatsAppLetsTalk" />
      </SmallButton>
    </StyledLongBox>
  )
}

import React from "react"
import { ReferralBanners } from "../components/Banners/ReferralBanners"
import PageWrapper from "../components/PageWrapper"
import Seo from "../components/Seo"
import Description from "../sections/landing1/Description"
import HaveDoubts from "../sections/landing1/HaveDoubts"
import Hero from "../sections/landing1/Hero"
import Testimonial from "../sections/landing1/Testimonial"
import ThreeKeyFeatures from "../sections/landing1/ThreeKeyFeatures"
import WhatWeHaveForYou from "../sections/landing1/WhatWeHaveForYou"
import PricingSection from "../sections/pricing/PricingSection"

const IndexPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Seo />
        <Hero />
        <ThreeKeyFeatures />
        <WhatWeHaveForYou />
        <Testimonial />

        <PricingSection
          isPage
          pt="0"
          pb="3rem"
          title="Suena bien, ¿verdad?"
          subtitle="Selecciona la opción que encaje mejor contigo."
        />
        <Description />
        <HaveDoubts />
      </PageWrapper>
    </>
  )
}
export default IndexPage

import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext, useMemo } from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Span, Text } from "../../components/Core"
import GlobalContext from "../../context/GlobalContext"
import imgStain2 from "../../images/svg/qm-closed.svg"
import imgStain1 from "../../images/svg/qm-opened.svg"
import { breakpoints, device } from "../../utils"

const query = graphql`
  query {
    allFile(
      filter: {
        relativePath: { regex: "/banner-testimonials/user-photo-[0-9+].png/" }
        ext: { eq: ".png" }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 90
            blurredOptions: { width: 50 }
          )
        }
      }
    }
  }
`

const TestimonialParentBox = styled(Box)`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;

  position: relative;
  min-height: 147px;
  top: 14px;
  width: 100%;
  height: 100%;
  min-height: 9.75rem;
  @media ${device.lg} {
    width: 100%;
  }

  border-radius: 12px;
  padding: 0.5rem;

  &:before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${imgStain1}), url(${imgStain2});
    background-repeat: no-repeat, no-repeat;
    background-position-x: left, right;
    background-position-y: top, bottom;
    @media (max-width: ${breakpoints.xl}px) {
      background-size: 2rem;
    }
    @media (max-width: ${breakpoints.lg}px) {
      background-size: 1.8rem;
    }
    @media (max-width: ${breakpoints.md}px) {
      background-size: 1.6rem;
    }
  }
  background-color: transparent;
`
const TestimonialChildBox = styled(Box)`
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 36, 50, 0.05);
  padding: 1.2rem 1.5rem;
  padding-left: 0;
`

const StyledContainer = styled(Container)`
  margin-top: 8.75rem;
  margin-bottom: 10rem;
  @media (max-width: ${breakpoints.xl}px) {
    max-width: 95vw !important;
  }
`

const HighlightedText = styled(Span)`
  font-weight: 600;
  color: ${({ theme, gContext }) =>
    gContext.isInteractuar ? theme.colors.navyBlue : theme.colors.green};
`

const Testimonial = () => {
  const gContext = useContext(GlobalContext)

  return (
    <StaticQuery
      query={query}
      render={data => {
        const testimonialItems = useMemo(() => {
          return [
            {
              image: (
                <GatsbyImage
                  image={getImage(data.allFile.nodes[0])}
                  alt="testimonials-user-0"
                  loading={"eager"}
                />
              ),
              text: "Factcil se encarga de todo! Facturan por mí, están pendientes de cobrar lo que me deben, son como mi secretaria virtual.",
              person: "Camila Andrea Rocha, Creator",
            },
            {
              image: (
                <GatsbyImage
                  image={getImage(data.allFile.nodes[1])}
                  alt="testimonials-user-1"
                  loading={"eager"}
                />
              ),
              text: "Con Factcil ya no debo esperar los plazos de pago pactados con mis clientes porque me adelantan el dinero y me ayudan con toda la carga administrativa; así me concentro mejor en mi trabajo.",
              person: "Alfredo Navarro, Actor/Modelo/Manager",
            },
            {
              image: (
                <GatsbyImage
                  image={getImage(data.allFile.nodes[2])}
                  alt="testimonials-user-2"
                  loading={"eager"}
                />
              ),
              text: "Tienen el mejor servicio para un freelancer; con ellos puedo pagar mi seguridad social, anticipar mis pagos, y me ayudan con todo el papeleo que las empresas me piden.",
              person: "Nina González, Editora de moda",
            },
          ]
        }, [data])

        return (
          <>
            {/* <!-- testimonial section --> */}
            <StyledContainer>
              <Text
                textAlign="center"
                mb={[3, 2, 4, 5]}
                fontSize={["1.5rem", "1.8rem", "2.2rem", "2.5rem", "2.6rem"]}
                color="primary"
                fontWeight={"bold"}
                lineHeight={["22px", "30px"]}
              >
                Ellos ya son parte de Factcil
                <HighlightedText gContext={gContext}>. </HighlightedText>
              </Text>
              <Row className="justify-content-start position-relative">
                {testimonialItems.map(testimonial => {
                  return (
                    <Col sx="12" md={6} lg={4}>
                      <TestimonialParentBox>
                        <TestimonialChildBox>
                          <Row className="w-100 h-100">
                            <>
                              <Col className="col-3 col-md-2 align-self-center p-2 p-md-0 ">
                                {testimonial.image}
                              </Col>

                              <Col className="col-9 col-md-10 d-flex justify-content-around flex-column">
                                <Text
                                  mb={"1"}
                                  fontSize={[
                                    "0.875rem",
                                    "0.875rem",
                                    "0.875rem",
                                    "0.875rem",
                                  ]}
                                  color="primary"
                                  opacity={0.9}
                                  fontWeight={"normal"}
                                  lineHeight={["20px"]}
                                >
                                  {testimonial.text}
                                </Text>
                                <Text
                                  fontSize={[
                                    "0.875rem",
                                    "0.875rem",
                                    "0.875rem",
                                    "0.875rem",
                                  ]}
                                  letterSpacing={"-0.56px"}
                                  color="primary"
                                  opacity={0.9}
                                  fontWeight={"600"}
                                  lineHeight={["17px"]}
                                >
                                  {testimonial.person}
                                </Text>
                              </Col>
                            </>
                          </Row>
                        </TestimonialChildBox>
                      </TestimonialParentBox>
                    </Col>
                  )
                })}
              </Row>
            </StyledContainer>
          </>
        )
      }}
    />
  )
}

export default Testimonial

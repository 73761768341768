import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { Box, Text, Title } from "../../components/Core"
import KeyFeaturePlant from "../../images/svg/KeyFeaturePlant.svg"
import KeyFigureChecklist from "../../images/svg/KeyFigureChecklist.svg"
import KeyFigureHandSoap from "../../images/svg/KeyFigureHandSoap.svg"

type ColType = {
  title: string
  text: string
  icon: any
  consideration?: string
}

const FeatureTitle = styled(Title)`
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.primary};
`
const FeatureText = styled(Text)`
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
`
const FeatureConsideration = styled(Text)`
  padding: 2.5rem 0;
  font-size: 0.75rem;
  line-height: 0.938rem;
  text-align: center;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.greyedOut};
`

const cols: ColType[] = [
  {
    title: "Visión de tu negocio.",
    text: "Sabemos que como independiente haces de todo y el tiempo no alcanza. Optimiza tu tiempo y dedícate a lo que mejor sabes hacer.",
    icon: KeyFeaturePlant,
  },
  {
    title: "No más desorden.",
    text: "Haz seguimiento al pago de tus cuentas de cobro enviando recordatorios a tus clientes. Lleva un control de tus ingresos y gastos.    ",
    icon: KeyFigureChecklist,
  },
  {
    title: "Calidad de vida.",
    text: "No es justo esperar tanto tiempo por el pago de tus servicios. Ahórrate esos largos plazos de pago y recibe tu dinero de forma inmediata*.",
    icon: KeyFigureHandSoap,
    consideration: "*5 días hábiles a partir de la solicitud",
  },
]

export default function ThreeKeyFeatures() {
  return (
    <Container className="d-flex justify-content-center align-items-center mb-4">
      <Row className="gx-5 gy-5">
        {cols.map((col: ColType, index: number) => (
          <Col xs={12} md={4} key={index}>
            <Row className="gy-4">
              <Box className="d-flex justify-content-center align-items-center">
                <img src={col.icon} className="" alt={`${index}-image`} />
              </Box>
              <FeatureTitle variant="card">{col.title}</FeatureTitle>
              <FeatureText variant="small">{col.text}</FeatureText>
              {col.consideration && (
                <FeatureConsideration variant="small">
                  {col.consideration}
                </FeatureConsideration>
              )}
            </Row>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

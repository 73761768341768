import { graphql, navigate, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"

import { Text, Title } from "../../components/Core"
import { default as checkIcon } from "../../images/svg/icons/checkIcon.svg"
import ArrowIcon from "../pricing/cards/ArrowIcon"
import { HighlightedText } from "../pricing/PricingSection"

interface OfferingType {
  title: string
  text: string
}

const query = graphql`
  query {
    AllWeHaveForYouImage: file(
      relativePath: { eq: "what-we-have-for-you.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 70
          blurredOptions: { width: 90 }
        )
      }
    }
  }
`

const OfferingTitle = styled(Text)`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.primary};
`
const OfferingText = styled(Text)`
  font-size: 1rem;
  font-weight: 400;

  line-height: 1.25rem;
  color: ${({ theme }) => theme.colors.primary};
`

const SmallButton = styled.button`
  text-decoration: none;
  margin: 0.75rem 0;
  width: 13rem;
  min-height: 3rem;
  background: transparent;
  color: ${({ theme }) => theme.colors.light};
  background-color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.02em;

  border: 1px solid #dfe1e9;
  box-sizing: border-box;
  border-radius: 40px;
  font-weight: 500;
  font-size: 0.875rem;

  img {
    margin-top: -5px;
    marging-left: 2px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.textHover};
    border-radius: 40px;
    text-align: center;
    color: #ffffff;
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
  }

  @media (max-width: 992px) {
    width: 10rem;
  }
`

const LeftDecorator = styled.div`
  position: absolute;
  width: 12.5rem;
  height: 12.5rem;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  transform: translate(-3rem, -12rem);
  overflow: hidden;
  z-index: -1;
`

const Diffuminator = styled.div`
  display: block;
  position: absolute;
  width: 25rem;
  height: 25rem;
  background: #dbf2fc;
  transform: translateY(-20rem);
  border-radius: 50%;

  filter: blur(6rem);
  z-index: -1;
`

const offerings: OfferingType[] = [
  {
    title: "Facturación",
    text: "Envía cuentas de cobro, cotizaciones y facturas a tus clientes.",
  },
  {
    title: "Pago de seguridad social",
    text: "Realiza el registro de pago de tu salud y aportes de ley desde Factcil.",
  },
  {
    title: "Anticipa tu pago",
    text: "Adelantamos el 100% del pago de tus servicios antes de la fecha límite.",
  },
]

export default function WhatWeHaveForYou() {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center my-5 pt-5">
      <Title variant="banner">
        Lo que tenemos para ti
        <HighlightedText>. </HighlightedText>
      </Title>
      <Row className="gx-5 gy-5 gy-lg-0 justify-content-center align-items-center">
        <Col xs={12} lg={8}>
          <StaticQuery
            query={query}
            render={data => {
              const image = getImage(data.AllWeHaveForYouImage)
              if (image)
                return (
                  <GatsbyImage
                    image={image}
                    alt={"left-form-image"}
                    loading="eager"
                  />
                )
              return
            }}
          />
          <LeftDecorator />
        </Col>
        <Col xs={12} lg={4}>
          <Diffuminator />
          <Row className="gy-5">
            {offerings.map((offering: OfferingType, index: number) => (
              <Col xs={12} key={index}>
                <Row className="justify-content-center align-items-center mb-3">
                  <Col xs={1}>
                    <img src={checkIcon} alt="checkIcon" />
                  </Col>
                  <Col xs={11}>
                    <OfferingTitle>{offering.title}</OfferingTitle>
                  </Col>
                </Row>
                <Row className="justify-content-center align-items-center">
                  <Col xs={1}>
                    <></>
                  </Col>
                  <Col xs={11}>
                    <OfferingText>{offering.text}</OfferingText>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          xs={12}
          className=" d-flex justify-content-center align-items-center justify-content-lg-end align-items-lg-end"
        >
          <SmallButton onClick={() => navigate("/how-it-works")}>
            Entérate de más{"\u00A0"}
            {"\u00A0"}
            <ArrowIcon color="#ffffff" />
          </SmallButton>
        </Col>
      </Row>
    </Container>
  )
}
